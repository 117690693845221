import { AxiosResponse } from "axios";
import { QueryClient } from "react-query";
import AbstractApi from "./AbstractApi";
import { AIDesignAssetType } from "./AIDesignAssetsApi";
import Team from "models/Team";
import { ShareAssetFolderPayload } from "hooks/useAssetFolders";

export interface AssetFolderType {
  id: number;
  name: string;
  teamId: number;
  team: Team;
  parentFolderId: number;
  createdat: string;
  updatedat: string;
  fullPath: {
    id: number;
    name: string;
  }[];
  copies?: AssetFolderType[];
  copyFromFolderId?: number;
}

export interface CreateAssetFolderPayload {
  name: string;
  teamId: number;
}

export interface AssetFolderPayload {
  id: number;
  name?: string;
  teamId: number;
}

export interface AddAssetFolderPayload {
  assetFolderId: number;
  teamId?: number;
  assetIds: number[];
}

export default class AssetFoldersApi extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getAssetFolders(
    teamId: number
  ): Promise<AxiosResponse<AssetFolderType[]>> {
    return await this.client.get("/asset-folders/my", {
      params: {
        teamId,
      },
    });
  }

  async getAssetSubFolders(assetFolderId: number): Promise<AssetFolderType[]> {
    return await this.client.get(
      `/asset-folders/my/${assetFolderId}/sub-folders`
    );
  }

  async getAssetFolder(id: number): Promise<AssetFolderType> {
    return await this.client.get(`/asset-folders/my/${id}`);
  }

  async createAssetFolder(
    payload: CreateAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(`/asset-folders/my`, payload);
  }

  async deleteAssetFolder(
    payload: AssetFolderPayload
  ): Promise<AxiosResponse<AssetFolderType>> {
    return await this.client.delete(`/asset-folders/my/${payload.id}`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async editAssetFolder(payload: AssetFolderPayload): Promise<AssetFolderType> {
    return await this.client.patch(`/asset-folders/my/${payload.id}`, {
      name: payload.name,
      teamId: payload.teamId,
    });
  }

  async getFolderAssets(
    payload: AssetFolderPayload
  ): Promise<{ data: AIDesignAssetType[] }> {
    return await this.client.get(`/asset-folders/my/${payload.id}/assets`, {
      params: {
        teamId: payload.teamId,
      },
    });
  }

  async addFolderAsset(
    payload: AddAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(
      `/asset-folders/my/${payload.assetFolderId}/add-assets`,
      {
        assetIds: payload.assetIds,
      }
    );
  }

  async removeFolderAsset(
    payload: AddAssetFolderPayload
  ): Promise<AssetFolderType> {
    return await this.client.post(
      `/asset-folders/my/${payload.assetFolderId}/remove-assets`,
      {
        assetIds: payload.assetIds,
      }
    );
  }

  async getRemainingFolders(): Promise<AssetFolderType[]> {
    let url = "/asset-folders/my?filter.parentFolderId=null";
    return await this.client.get(url);
  }

  async shareAssetFolder(
    id: number,
    payload: Partial<ShareAssetFolderPayload>
  ): Promise<any> {
    return await this.client.post(`/asset-folders/my/${id}/share`, payload);
  }

  async undoSharingAssetFolder(
    id: number,
    payload: Partial<ShareAssetFolderPayload>
  ): Promise<any> {
    return await this.client.post(
      `/asset-folders/my/${id}/undo-sharing`,
      payload
    );
  }
}
