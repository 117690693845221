import AbstractApi from "./AbstractApi";
import { QueryClient } from "react-query";

export interface IBrandReportsPayload {
  name?: string;
  brandNames: string[];
  bookmarkedProducts: string[];
}

export interface IBrandReports {
  name?: string;
  brandNames: string[];
  bookmarkedProducts: string[];
}

export default class ReportsAPI extends AbstractApi {
  constructor(
    userToken: string | null,
    teamId: number | null,
    auth: any,
    queryClient: QueryClient,
    portalId?: number
  ) {
    super(userToken, teamId, auth, queryClient, portalId);
  }

  async getBrandReports(teamId: number): Promise<IBrandReports[]> {
    return await this.client.get("/brand-reports/my", {
      headers: {
        teamId: teamId,
      },
    });
  }

  async getBrandReport(id: number, teamId: number): Promise<IBrandReports> {
    return await this.client.get(`/brand-reports/my/${id}`, {
      headers: {
        teamId: teamId,
      },
    });
  }

  async createBrandReport(
    payload: IBrandReportsPayload
  ): Promise<IBrandReports> {
    return await this.client.post(`/brand-reports/my`, payload);
  }

  async updateBrandReport(
    id: number,
    payload: IBrandReportsPayload
  ): Promise<IBrandReports> {
    return await this.client.patch(`/brand-reports/my/${id}`, payload);
  }

  async deleteBrandReport(id: number): Promise<IBrandReports> {
    return await this.client.delete(`/brand-reports/my/${id}`);
  }
}
