import { useEffect, useMemo, useState, memo } from "react";
import cn from "classnames";
import CustomButton from "components/atoms/CustomButton";
import RangeChart from "components/atoms/TrendChart/RangeChart";
import { debounce } from "lodash";
import useTranslation from "next-translate/useTranslation";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { RangeType } from "../DateRangeChartPopover";
import { convertStringToDate } from "utils/convertDate";
import { convertDate } from "utils/dates";
import dayjs from "dayjs";

export interface DateRangeChartProps {
  range: RangeType;
  onApply: (range: RangeType) => void;
  chartData: { x: number; y: number }[];
  className?: string;
  hideTitle?: boolean;
  disableInput?: boolean;
  hideActionButtons?: boolean;
  chartWrapperClassName?: string;
  close?: Function;
}

const DateRangeChart = ({
  range,
  onApply,
  chartData,
  className,
  hideTitle = false,
  disableInput = false,
  hideActionButtons = false,
  chartWrapperClassName,
  close,
}: DateRangeChartProps) => {
  const [stDate, setStDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [enDate, setEnDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const [dateRange, setDateRange] = useState<RangeType>({ min: 0, max: 0 });
  const { t } = useTranslation("common");

  const { maxDate, minDate } = useMemo(() => {
    const dates = chartData.map((item) => item.x);
    return { maxDate: Math.max(...dates), minDate: Math.min(...dates) };
  }, [chartData]);

  useEffect(() => {
    const handleApply = debounce(() => {
      const minChanged = dateRange.min !== range.min;
      const maxChanged = dateRange.max !== range.max;

      if (
        hideActionButtons &&
        (range.min !== range.max ||
          dateRange.max !== maxDate ||
          dateRange.min !== minDate) &&
        (minChanged || maxChanged)
      ) {
        onApply(dateRange);
      }
    }, 500);

    handleApply();

    return () => {
      handleApply.cancel();
    };
  }, [hideActionButtons, dateRange]);

  useEffect(() => {
    if (range.max !== range.min && range.max !== 0) {
      setDateRange(range);
      setStDate({
        startDate: convertStringToDate(range.min, "YYYY-MM-DD"),
        endDate: convertStringToDate(range.min, "YYYY-MM-DD"),
      } as DateValueType);

      setEnDate({
        startDate: convertStringToDate(range.max, "YYYY-MM-DD"),
        endDate: convertStringToDate(range.max, "YYYY-MM-DD"),
      } as DateValueType);
    } else {
      setDateRange({
        min: minDate,
        max: maxDate,
      });
      setStDate({
        startDate: convertStringToDate(minDate, "YYYY-MM-DD"),
        endDate: convertStringToDate(minDate, "YYYY-MM-DD"),
      } as DateValueType);
      setEnDate({
        startDate: convertStringToDate(maxDate, "YYYY-MM-DD"),
        endDate: convertStringToDate(maxDate, "YYYY-MM-DD"),
      } as DateValueType);
    }
  }, [maxDate, minDate]);

  return (
    <>
      <div className={cn("p-6 pb-4 flex flex-col gap-4", className)}>
        {!hideTitle && (
          <div>
            <div className="text-typography-headline text-sm leading-tight font-medium">
              {t("date_range")}
            </div>
            <div className="text-typography-headline text-xs leading-normal font-normal mt-1">
              {t("date_range_description")}
            </div>
          </div>
        )}
        <div className={cn("w-[400px] h-14", chartWrapperClassName)}>
          <RangeChart
            minValue={minDate}
            maxValue={maxDate}
            chartData={chartData}
            range={dateRange}
            onChange={(value: RangeType) => {
              setStDate({
                startDate: convertStringToDate(value.min, "YYYY-MM-DD"),
                endDate: convertStringToDate(value.min, "YYYY-MM-DD"),
              } as DateValueType);

              setEnDate({
                startDate: convertStringToDate(value.max, "YYYY-MM-DD"),
                endDate: convertStringToDate(value.max, "YYYY-MM-DD"),
              } as DateValueType);
              setDateRange(value);
            }}
            step={24 * 60 * 60 * 1000 * 10}
            customTooltip={(value) => dayjs(value.x).format("MM/YYYY")}
          />
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="relative !max-w-[110px] w-full">
            {disableInput ? (
              <div className="text-body text-xs leading-normal text-left">
                {stDate?.startDate &&
                  convertDate(stDate?.startDate.toString(), "MMM YYYY")}
              </div>
            ) : (
              <Datepicker
                minDate={new Date(minDate)}
                maxDate={new Date(dateRange.max)}
                useRange={false}
                asSingle={true}
                value={stDate}
                displayFormat="MMM YYYY"
                onChange={(date: DateValueType) => {
                  setStDate(date);
                  setDateRange({
                    ...dateRange,
                    min: new Date(date?.startDate || "").getTime(),
                  });
                }}
                classNames={{
                  input: () =>
                    cn(
                      "relative transition-all border border-border-normal rounded-md duration-300 py-[5px] pl-2 pr-8",
                      "w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 tracking-wide",
                      "font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed",
                      "focus:border-blue-500 focus:ring-blue-500/20"
                    ),
                }}
              />
            )}
          </div>
          <div className="relative !max-w-[110px] w-full">
            {disableInput ? (
              <div className="text-body text-xs leading-normal text-right">
                {enDate?.startDate &&
                  convertDate(enDate?.startDate.toString(), "MMM YYYY")}
              </div>
            ) : (
              <Datepicker
                minDate={new Date(dateRange.min)}
                maxDate={new Date(maxDate)}
                useRange={false}
                asSingle={true}
                value={enDate}
                onChange={(date: DateValueType) => {
                  setEnDate(date);
                  setDateRange({
                    ...dateRange,
                    max: new Date(date?.startDate || "").getTime(),
                  });
                }}
                displayFormat="MMM YYYY"
                classNames={{
                  input: () =>
                    cn(
                      "relative transition-all border border-border-normal rounded-md duration-300 py-[5px] pl-2 pr-8",
                      "w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 tracking-wide",
                      "font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed",
                      "focus:border-blue-500 focus:ring-blue-500/20"
                    ),
                }}
              />
            )}
          </div>
        </div>
      </div>
      {!hideActionButtons && (
        <div className="p-6 pt-2 flex items-center justify-end gap-2">
          <CustomButton
            label={t("Cancel")}
            variant="tertiary"
            onClick={() => {
              onApply({ min: 0, max: 0 });
              close && close();
            }}
          />
          <CustomButton
            label={t("Apply")}
            onClick={() => {
              onApply(dateRange);
              close && close();
            }}
          />
        </div>
      )}
    </>
  );
};

export default memo(DateRangeChart);
