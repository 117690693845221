import React, { useMemo } from "react";
import isArray from "lodash/isArray";
import cn from "classnames";
import { useBreakpoint } from "contexts/BreakpointContext";
import SkeletonLoading from "./SkeletonLoading";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const MAX_IMAGE_WIDTH = 2000;

const setImageWidth = (url: string, screenWidth?: number) => {
  return `${url}${
    screenWidth
      ? `?width=${
          screenWidth > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : screenWidth
        }`
      : ""
  } `;
};

interface IImageResponsiveProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  url?: string | string[];
  className?: string;
  showEmptyState?: boolean;
  imageLoading?: boolean;
}

const ImageResponsive: React.FC<IImageResponsiveProps> = ({
  url,
  width,
  height,
  className,
  showEmptyState = true,
  imageLoading = false,
  ...props
}) => {
  const { screenWidth } = useBreakpoint();
  const [imageSrc, setImageSrc] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isError) {
      setImageSrc("/images/no_image_53x64.png");
    } else {
      if (url) {
        const rect = ref?.current?.getBoundingClientRect();

        setImageSrc(
          setImageWidth(
            isArray(url) ? url[0] : url,
            rect?.width
              ? Math.floor(rect?.width) < 128
                ? 128
                : Math.floor(rect?.width)
              : 512
          )
        );
      }
    }
  }, [url, isError, screenWidth]);

  const handleError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  const isNoImage = useMemo(
    () => imageSrc === "/images/no_image_53x64.png",
    [imageSrc]
  );

  return (
    <div
      key={imageSrc}
      ref={ref}
      style={{ width, height }}
      className={cn(
        "w-min-content relative",
        isNoImage && "flex items-center justify-center",
        className
      )}
    >
      {!!imageSrc && (
        <>
          {(isLoading || imageLoading) && (
            <SkeletonLoading
              className={cn("!absolute z-10 left-0 top-0", className)}
              height={height}
              width={width}
            />
          )}
          <img
            onLoad={() => setIsLoading(false)}
            onError={handleError}
            className={cn(
              "object-contain",
              (isLoading || imageLoading) && "w-0 h-0",
              className,
              !isLoading &&
                !imageLoading &&
                isNoImage &&
                "max-h-[64px] max-w-[53px]"
            )}
            width={width}
            height={height}
            alt="image"
            loading="lazy"
            referrerPolicy="no-referrer"
            {...props}
            src={!showEmptyState && isError ? "" : imageSrc}
          />
        </>
      )}
    </div>
  );
};

export default ImageResponsive;
