import cn from "classnames";
import React, { useMemo } from "react";
import MultiRangeSlider from "../MultiRangeSlider";
import { RangeType } from "components/molecules/DateRangeChartPopover";
import Tooltip from "../Tooltip";

interface RangeChartProps {
  minValue: number;
  maxValue: number;
  range: RangeType;
  chartData: { x: number; y: number }[];
  onChange: (range: RangeType) => void;
  step?: number;
  customTooltip?: (value: { x: number; y: number }) => string;
}

const RangeChart = ({
  minValue,
  maxValue,
  range,
  chartData,
  onChange,
  step,
  customTooltip,
}: RangeChartProps) => {
  const { max, min } = useMemo(() => {
    const prices = chartData.map((item) => item.y);
    return { max: Math.max(...prices), min: Math.min(...prices) };
  }, [chartData]);

  return (
    <div className="relative w-full h-12">
      <div className="w-full h-full flex items-end justify-start border-b border-gray-50">
        {chartData.map((item, index) => {
          const height = (item.y / (max - min)) * 100;
          return (
            <div
              className={cn(
                "range-chart-bar w-[10.75px] mx-[1px] h-full cursor-pointer",
                item.x >= range.min && item.x <= range.max
                  ? "bg-gray-950"
                  : "bg-[#F0F0F0]"
              )}
            >
              <Tooltip
                content={customTooltip ? customTooltip(item) : item.x}
                direction="top"
              >
                <div
                  key={index}
                  className="bg-white"
                  style={{
                    height: `${100 - height}%`,
                  }}
                >
                  <div
                    className={cn("rounded-t-[1px] h-full")}
                    style={{
                      borderRadius: height <= 5 ? `0` : "",
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
      <div className="absolute -bottom-[7.5px] w-full">
        <MultiRangeSlider
          min={minValue}
          max={maxValue}
          rangeValues={range}
          onChange={(value: any) => {
            onChange(value);
          }}
          step={step}
        />
      </div>
    </div>
  );
};

export default React.memo(RangeChart);
